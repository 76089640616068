module.exports = {
    "防骗指南": "防騙指南",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "本平台所有資源均為實名制，小姐姐與小哥哥皆是VIP會員請對雙方需一定的尊重，請不要出言不遜沒有素質，經發現將取消約炮資格請謹記！ ，重要的事情說三遍！ ！ ！",
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "小姐姐是不會收取任何費用，只需完成平台三次打賞任務獲得約炮名額即可享受同城約炮資源，開房花銷需自理哦！",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "妹子已到樓下,要求付【全款才能上樓】均為騙子無疑，請立即來平台聯繫接待客服舉報！",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "友情提醒：老師，接待員，派單員，都不會私加客戶的聯繫方式，上班時間均為 13.00~01.00 其餘時間均不辦理業務，若有在下班時間他人給你發送信息請不要相信避免資金受損我方概不負責！ ！ ！",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "凡是要求下載其他軟件的都是騙子,請立即截圖來平台聯繫接待客服舉報！",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "本平台對每一位VIP會員尋歡經歷負責,遇到任何問題請及時來平台聯繫接待客服舉報,官方核實後退還解鎖消耗!",
    "预约大厅": "預約大廳",
    "会员视频": "會員視頻",
    "骑在身上大屌套弄": "騎在身上大屌套弄",
    "选妃中心": "選妃中心",
    "新加入": "新加入",
    "附近的人": "附近的人",
    "为您发现同城炮友 999+ 位": "為您發現同城炮友 999+ 位",
    "刷新成功": "刷新成功",
    "为您发现同城炮友": "為您發現同城炮友",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "本平台所有資源真實、安全、可靠、全國空降 選取您心儀的她 隨時隨地 開啟約啪~",
    "联系专属接待客服": "聯繫專屬接待客服",
    "开卡可见": "開卡可見",
    "寶衡传媒打分": "廣發國際打分",
    "美女颜值": "美女顏值",
    "详细地址：已隐藏 联系小蜜开卡可见": "詳細地址：已隱藏 聯繫小蜜開卡可見",
    "联系方式：已隐藏 联系小蜜开卡可见": "聯繫方式：已隱藏 聯繫小蜜開卡可見",
    "基本信息 20岁,身高 175": "基本信息 {age}歲,身高 {height}",
    "所在地区": "所在地區",
    "服务项目 激活可见": "服務項目 激活可見",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "祝會員們，天天約得美嬌娘，夜夜都能當新郎！",
    "立即约她": "立即約她",
    "请联系专属客服接待": "請聯繫專屬客服接待",
    "寶衡传媒": "廣發國際",
    "华人第一福利品牌": "港灣第一福利品牌",
    "为了会员权益，网站只展示": "為了會員權益，網站只展示",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "資源並且不定期更新只為展示實力，如需查詢預約更多資源請聯繫上級接待",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "本平台所有資源真實丶安全丶可靠，全國空降丶同城約炮選取你心儀的她，與您零距離邂逅，快加入我們吧",
    "寶衡资源": "廣發國際",
    "昵称": "暱稱",
    "类型": "類型",
    "服务项目: 激活可见": "服務項目: 激活可見",
    "已认证": "已認證",
    "实时配对": "實時配對",
    "约炮心得": "約炮心得",
    "服务条款": "服務條款",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "本平台全網最真實外圍+商務伴遊+同城激情，為保證每個用戶的個人隱私，客戶僅限通過聯繫接待員或平台資深會員實名推薦才可加入。",
    "有哪些资源?": "有哪些資源?",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "網紅、模特、空姐、嫩模、大學生，只有您想不到，沒有本平台辦不到",
    "服务范围": "服務範圍",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "服務項目：做愛3次 可無套內射 深喉口交 漫遊  毒龍 按摩",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "可拍照留念： 私處特寫 做愛錄屏 口交錄屏",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "可指定制服：護士-女僕-空姐-秘書-女警-貓女",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "可捆綁輕虐：配合使用各式男女情趣性愛道具",
    "北京": "北京",
    "上海": "上海",
    "广州": "廣州",
    "深圳": "深圳",
    "南京": "南京",
    "长沙": "長沙",
    "重庆": "重慶",
    "西安": "西安",
    "丁先生": "丁先生",
    "通过本站已预约......": "通過本站已預約......",
    "朱先生": "朱先生",
    "正在服务中......": "正在服務中......",
    "李先生": "李先生",
    "郭先生": "郭先生",
    "路先生": "路先生",
    "郝先生": "郝先生",
    "赵先生": "趙先生",
    "祝先生": "祝先生",
    "牛先生": "牛先生",
    "张先生": "張先生",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "聯繫約好時間叫我去她家操她，皮膚還是很緊緻",
    "颜值很高，服务很专业，还会热舞...": "顏值很高，服務很專業，還會熱舞...",
    "床上满分 可一字马 性格超好 氵超多": "床上滿分 可一字馬 性格超好 氵超多",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "朋友介紹的，妞可以年輕漂亮，服務夠莞式。",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "今天正好有空閒，正好去驗證一下，就約了一下。見面一看，胸不大不小，身材很好，和照片一致。",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "進門一看，輕熟女，我喜歡少婦，穿上高跟踩上更 突顯身材。簡單洗洗開工，口的很舒服，姐口的包裹感十足",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "很是用心，人也比較魅，比較騷，說話溫柔，騷話說起來一套一套，讓人興奮度比較高，能激起男性的性慾",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "妹子看上去很小，服務一般，身子有點胖態度還是可以的 喜歡嫩的可以去試試...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "妹子長相甜美，一顆美人痣最為誘惑身材不錯，喜歡旅遊，性格溫柔開朗",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "妹子比較嫩，顏值比較高，就是皮膚有點黑。妹子最近才回上海，簡單服務陪浴口做。",
    "登录": "登錄",
    "请输入用户名": "請輸入用戶名",
    "请输入密码": "請輸入密碼",
    "忘记密码？": "忘記密碼？",
    "没有账户？马上注册": "沒有賬戶？馬上註冊",
    "注册": "註冊",
    "请输入用户名(6-12个英文字母或数字)": "請輸入用戶名(6-12個英文字母或數字)",
    "请输入登录密码(6-12个英文字母或数字)": "請輸入登錄密碼(6-12個英文字母或數字)",
    "我已经知晓并同意'开户协议'各项条约": "我已經知曉並同意'開戶協議'各項條約",
    "请输入邀请码": "請輸入邀請碼",
    "请勾选下方开户协议": "請勾選下方開戶協議",
    "预约做单": "预约做单",
    "距 第": "距 第",
    "期 截止": "期 截止",
    "第": "第",
    "期": "期",
    "糖心": "大",
    "烟花": "小",
    "双份": "雙",
    "单份": "單",
    "双": "雙",
    "单": "單",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "本次數據作為直播間打賞主播人氣置頂主播排行榜,正式客戶完成後可免費開直播間權限",
    "与主播私下约啪": "與主播私下約啪",
    "任务单": "任務單",
    "可用余额": "可用餘額",
    "元": "積分",
    "打赏": "打賞",
    "当前选号": "當前選號",
    "打赏金额": "打賞積分",
    "请输入金额": "積分",
    "总共打赏": "總共打賞",
    "合计": "合計",
    "清空订单": "清空訂單",
    "确认打赏": "確認打賞",
    "期号": "期號",
    "正确打赏": "正確打賞",
    "约炮数据一": "約炮數據一",
    "约炮数据二": "約炮數據二",
    "约炮数据三": "約炮數據三",
    "高级VIP数据": "高級VIP數據",
    "填写收款卡": "填寫收款卡",
    "请输入您的收款卡信息": "請輸入您的收款卡信息",
    "请输入真实银行卡号": "請輸入真實銀行卡號",
    "请选择银行": "請選擇銀行",
    "银行卡号": "銀行卡號",
    "银行名称": "銀行名稱",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "尊敬的用戶,為了保障您的資金安全,請您確認您的真實姓名和設置取款密碼,如果姓名與開戶名不一致,將無法取款",
    "确认提现": "確認提現",
    "任务记录": "任務記錄",
    "数据为空": "數據為空",
    "打赏积分": "打賞積分",
    "下单时间": "下單時間",
    "结算时间": "結算時間",
    "充值": "充值",
    "提现": "提現",
    "我的钱包": "我的錢包",
    "详情": "詳情",
    "余额(元)": "餘額",
    "个人报表": "個人報表",
    "账户明细": "賬戶明細",
    "打赏记录": "打賞記錄",
    "个人中心": "個人中心",
    "信息公告": "信息公告",
    "在线客服": "在線客服",
    "请完成任务单后进入": "請完成任務單後進入",
    "请联系客服充值": "請聯繫客服充值",
    "请设置收款卡": "請設置收款卡",
    "功能已禁用": "功能已禁用",
    "登录/注册": "登錄/註冊",
    "登录可享受更多服务": "登錄可享受更多服務",
    "基本信息": "基本信息",
    "头像": "頭像",
    "选择头像": "選擇頭像",
    "确定": "確定",
    "真实姓名": "真實姓名",
    "未设置": "未設置",
    "性别": "性別",
    "男": "男",
    "女": "女",
    "未知": "未知",
    "绑定信息": "綁定信息",
    "已绑定": "已綁定",
    "无": "無",
    "系统公告": "系統公告",
    "盈利金额(元)": "盈利金額(元)",
    "盈利计算公式 : 盈利金额 - 任务金额": "盈利計算公式 : 盈利金額 - 任務金額",
    "任务金额": "任務金額",
    "充值金额": "打賞金額",
    "提现金额": "提現金額",
    "中奖金额": "收益金額",
    "蜜獾娱乐": "廣發國際",
    "联系": "聯繫",
    "全天7 * 24小时竭诚为您服务": "全天7 * 24小時竭誠為您服務",
    "收款卡信息": "收款卡信息",
    "添加收款卡": "添加收款卡",
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "提示:請設置大型商業銀行,如需修改,請您聯繫在線客服",
    "请设置姓名后再绑定银行卡": "請設置姓名後再綁定銀行卡",
    "请设置提现密码后再绑定银行卡": "請設置提現密碼後再綁定銀行卡",
    "修改登录密码": "修改登錄密碼",
    "保存": "保存",
    "旧密码": "舊密碼",
    "请输入您的旧密码": "請輸入您的舊密碼",
    "新密码": "新密碼",
    "请输入您的新密码": "請輸入您的新密碼",
    "请填写完整": "請填寫完整",
    "两次密码输入不一致": "兩次密碼輸入不一致",
    "修改真实姓名": "修改真實姓名",
    "姓名": "姓名",
    "请输入真实姓名": "請輸入真實姓名",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "為了您賬戶安全,真實姓名需要與綁定銀行卡姓名一致",
    "请勿重复设置": "請勿重複設置",
    "请输入姓名": "請輸入姓名",
    "设置资金密码": "設置資金密碼",
    "请输入资金密码": "請輸入資金密碼",
    "请再次输入资金密码": "請再次輸入資金密碼",
    "两次密码不一致": "兩次密碼不一致",
    "性别修改": "性別修改",
    "设置": "設置",
    "基本信息设置": "基本信息設置",
    "登录密码": "登錄密碼",
    "资金密码": "資金密碼",
    "退出登录": "退出登錄",
    "提现密码已设置，需要修改请联系客服": "提現密碼已設置，需要修改請聯繫客服",
    "已设置": "已設置",
    "提现中心": "提現中心",
    "提现记录": "提現記錄",
    "提现金额 (元)": "兌換積分",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "到賬時間：一般到賬時間在5分鐘左右，最快2分鐘內到賬",
    "说明": "說明",
    "余额": "餘額",
    "马上提现": "馬上提現",
    "请填写正确的金额": "請填寫正確的金額",
    "金额": "金額",
    "提交时间": "提交時間",
    "审核时间": "審核時間",
    "没有更多了": "沒有更多了",
    "播放": "播放",
    "次播放": "次播放",
    "热门推荐": "熱門推薦",
    "账号下线": "賬號下線",
    "请充值后观看视频": "請充值後觀看視頻",
    "直播间": "直播間",
    "官方认证女神": "官方認證女神",
    "已缴纳20000保证金": "已繳納20000保證金",
    "地区": "地區",
    "签名": "簽名",
    "获取联系方式": "獲取聯繫方式",
    "温馨提示": "溫馨提示",
    "联系数据老师激活权限": "聯繫數據老師激活權限",
    "取消": "取消",
    "标题": "標題",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "服務項目：做愛3次可無套內射深喉口交漫遊毒龍按摩",
    "首页": "首頁",
    "预约": "預約",
    "我的": "我的",
    "注": "注",
    "金额错误": "金額錯誤",
    "请选号": "請選號",
    "请填写金额": "請填寫金額",
    "余额不足，请联系客服充值": "餘額不足，請聯繫客服充值",
    "请联系管理员领取该任务": "請聯繫管理員領取該任務",
    "开奖成功，期号": "開獎成功，期號",
    "玫瑰": "玫瑰",
    "火箭": "火箭",
    "添加转数快":"添加轉數快",
    "填写转数快":"填寫轉數快",
    "请输入您的转数快信息":"請輸入您的轉數快信息",
    "联系电话":"聯繫電話",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合":"根據銀聯風控要求同卡24小時內驗證次數不能超過4次，感謝配合",
    "确认":"確認",
    "已存在提款方式，请勿重复绑定":"已存在提款方式，請勿重複綁定！",
    "信誉分 : ": "信譽分 : ",
}
