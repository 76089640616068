module.exports = {
    "防骗指南": "防骗指南",
    "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！": "本平台所有资源均为实名制，小姐姐与小哥哥皆是VIP会员请对双方需一定的尊重，请不要出言不逊没有素质，经发现将取消约炮资格请谨记！，重要的事情说三遍！！！",
    "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！": "小姐姐是不会收取任何费用，只需完成平台三次打赏任务获得约炮名额即可享受同城约炮资源，开房花销需自理哦！",
    "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！": "妹子已到楼下,要求付【全款才能上楼】均为骗子无疑，请立即来平台联系接待客服举报！",
    "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！": "友情提醒：老师，接待员，派单员，都不会私加客户的联系方式，上班时间均为 13.00~01.00 其余时间均不办理业务，若有在下班时间他人给你发送信息请不要相信避免资金受损我方概不负责！！！",
    "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！": "凡是要求下载其他软件的都是骗子,请立即截图来平台联系接待客服举报！",
    "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!": "本平台对每一位VIP会员寻欢经历负责,遇到任何问题请及时来平台联系接待客服举报,官方核实后退还解锁消耗!",
    "预约大厅": "预约大厅",
    "会员视频": "会员视频",
    "骑在身上大屌套弄": "骑在身上大屌套弄",
    "选妃中心": "选妃中心",
    "新加入": "新加入",
    "附近的人": "附近的人",
    "为您发现同城炮友 999+ 位": "为您发现同城炮友 999+ 位",
    "刷新成功": "刷新成功",
    "为您发现同城炮友": "为您发现同城炮友",
    "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~": "本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~",
    "联系专属接待客服": "联系专属接待客服",
    "开卡可见": "开卡可见",
    "寶衡传媒打分": "广发国际打分",
    "美女颜值": "美女颜值",
    "详细地址：已隐藏 联系小蜜开卡可见": "详细地址：已隐藏 联系小蜜开卡可见",
    "联系方式：已隐藏 联系小蜜开卡可见": "联系方式：已隐藏 联系小蜜开卡可见",
    "基本信息 20岁,身高 175": "基本信息 {age}岁,身高 {height}",
    "所在地区": "所在地区",
    "服务项目 激活可见": "服务项目 激活可见",
    "祝会员们，天天约得美娇娘，夜夜都能当新郎！": "祝会员们，天天约得美娇娘，夜夜都能当新郎！",
    "立即约她": "立即约她",
    "请联系专属客服接待": "请联系专属客服接待",
    "寶衡传媒": "广发国际",
    "华人第一福利品牌": "华人第一福利品牌",
    "为了会员权益，网站只展示": "为了会员权益，网站只展示",
    "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待": "资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待",
    "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧": "本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧",
    "寶衡资源": "广发国际",
    "昵称": "昵称",
    "类型": "类型",
    "服务项目: 激活可见": "服务项目: 激活可见",
    "已认证": "已认证",
    "实时配对": "实时配对",
    "约炮心得": "约炮心得",
    "服务条款": "服务条款",
    "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。": "本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。",
    "有哪些资源?": "有哪些资源?",
    "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到": "网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到",
    "服务范围": "服务范围",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩": "服务项目：做爱3次 可无套内射 深喉口交 漫游  毒龙 按摩",
    "可拍照留念： 私处特写 做爱录屏 口交录屏": "可拍照留念： 私处特写 做爱录屏 口交录屏",
    "可指定制服：护士-女仆-空姐-秘书-女警-猫女": "可指定制服：护士-女仆-空姐-秘书-女警-猫女",
    "可捆绑轻虐：配合使用各式男女情趣性爱道具": "可捆绑轻虐：配合使用各式男女情趣性爱道具",
    "北京": "北京",
    "上海": "上海",
    "广州": "广州",
    "深圳": "深圳",
    "南京": "南京",
    "长沙": "长沙",
    "重庆": "重庆",
    "西安": "西安",
    "丁先生": "丁先生",
    "通过本站已预约......": "通过本站已预约......",
    "朱先生": "朱先生",
    "正在服务中......": "正在服务中......",
    "李先生": "李先生",
    "郭先生": "郭先生",
    "路先生": "路先生",
    "郝先生": "郝先生",
    "赵先生": "赵先生",
    "祝先生": "祝先生",
    "牛先生": "牛先生",
    "张先生": "张先生",
    "联系约好时间叫我去她家操她，皮肤还是很紧致": "联系约好时间叫我去她家操她，皮肤还是很紧致",
    "颜值很高，服务很专业，还会热舞...": "颜值很高，服务很专业，还会热舞...",
    "床上满分 可一字马 性格超好 氵超多": "床上满分 可一字马 性格超好 氵超多",
    "朋友介绍的，妞可以年轻漂亮，服务够莞式。": "朋友介绍的，妞可以年轻漂亮，服务够莞式。",
    "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。": "今天正好有空闲，正好去验证一下，就约了一下。见面一看，胸不大不小，身材很好，和照片一致。",
    "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足": "进门一看，轻熟女，我喜欢少妇，穿上高跟踩上更 突显身材。简单洗洗开工，口的很舒服，姐口的包裹感十足",
    "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲": "很是用心，人也比较魅，比较骚，说话温柔，骚话说起来一套一套，让人兴奋度比较高，能激起男性的性欲",
    "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...": "妹子看上去很小，服务一般，身子有点胖态度还是可以的 喜欢嫩的可以去试试...",
    "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗": "妹子长相甜美，一颗美人痣最为诱惑身材不错，喜欢旅游，性格温柔开朗",
    "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。": "妹子比较嫩，颜值比较高，就是皮肤有点黑。妹子最近才回上海，简单服务陪浴口做。",
    "登录": "登录",
    "请输入用户名": "请输入用户名",
    "请输入密码": "请输入密码",
    "忘记密码？": "忘记密码？",
    "没有账户？马上注册": "没有账户？马上注册",
    "注册": "注册",
    "请输入用户名(6-12个英文字母或数字)": "请输入用户名(6-12个英文字母或数字)",
    "请输入登录密码(6-12个英文字母或数字)": "请输入登录密码(6-12个英文字母或数字)",
    "我已经知晓并同意'开户协议'各项条约": "我已经知晓并同意'开户协议'各项条约",
    "请输入邀请码": "请输入邀请码",
    "请勾选下方开户协议": "请勾选下方开户协议",
    "预约做单": "预约做单",
    "距 第": "距 第",
    "期 截止": "期 截止",
    "第": "第",
    "期": "期",
    "糖心": "糖心",
    "烟花": "烟花",
    "双份": "双份",
    "单份": "单份",
    "双": "双",
    "单": "单",
    "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限": "本次数据作为直播间打赏主播人气置顶主播排行榜,正式客户完成后可免费开直播间权限",
    "与主播私下约啪": "与主播私下约啪",
    "任务单": "任务单",
    "可用余额": "可用余额",
    "元": "元",
    "打赏": "打赏",
    "当前选号": "当前选号",
    "打赏金额": "打赏积分",
    "请输入金额": "请输入金额",
    "总共打赏": "总共打赏",
    "合计": "合计",
    "清空订单": "清空订单",
    "确认打赏": "确认打赏",
    "确认":"确认",
    "期号": "期号",
    "正确打赏": "正确打赏",
    "约炮数据一": "约炮数据一",
    "约炮数据二": "约炮数据二",
    "约炮数据三": "约炮数据三",
    "高级VIP数据": "高级VIP数据",
    "填写收款卡": "填写收款卡",
    "请输入您的收款卡信息": "请输入您的收款卡信息",
    "请输入真实银行卡号": "请输入真实银行卡号",
    "请选择银行": "请选择银行",
    "银行卡号": "银行卡号",
    "银行名称": "银行名称",
    "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款": "尊敬的用户,为了保障您的资金安全,请您确认您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款",
    "确认提现": "确认提现",
    "任务记录": "任务记录",
    "数据为空": "数据为空",
    "打赏积分": "打赏积分",
    "下单时间": "下单时间",
    "结算时间": "结算时间",
    "充值": "充值",
    "提现": "提现",
    "我的钱包": "我的钱包",
    "详情": "详情",
    "余额(元)": "余额(元)",
    "个人报表": "个人报表",
    "账户明细": "账户明细",
    "打赏记录": "打赏记录",
    "个人中心": "个人中心",
    "信息公告": "信息公告",
    "在线客服": "在线客服",
    "请完成任务单后进入": "请完成任务单后进入",
    "请联系客服充值": "请联系客服充值",
    "请设置收款卡": "请设置收款卡",
    "功能已禁用": "功能已禁用",
    "登录/注册": "登录/注册",
    "登录可享受更多服务": "登录可享受更多服务",
    "基本信息": "基本信息",
    "头像": "头像",
    "选择头像": "选择头像",
    "确定": "确定",
    "真实姓名": "真实姓名",
    "未设置": "未设置",
    "性别": "性别",
    "男": "男",
    "女": "女",
    "未知": "未知",
    "绑定信息": "绑定信息",
    "已绑定": "已绑定",
    "无": "无",
    "系统公告": "系统公告",
    "盈利金额(元)": "盈利金额(元)",
    "盈利计算公式 : 盈利金额 - 任务金额": "盈利计算公式 : 盈利金额 - 任务金额",
    "任务金额": "任务金额",
    "充值金额": "充值金额",
    "提现金额": "提现金额",
    "中奖金额": "中奖金额",
    "蜜獾娱乐": "蜜獾娱乐",
    "联系": "联系",
    "全天7 * 24小时竭诚为您服务": "全天7 * 24小时竭诚为您服务",
    "收款卡信息": "收款卡信息",
    "添加收款卡": "添加收款卡",
    "提示:请设置大型商业银行,如需修改,请您联系在线客服": "提示:请设置大型商业银行,如需修改,请您联系在线客服",
    "请设置姓名后再绑定银行卡": "请设置姓名后再绑定银行卡",
    "请设置提现密码后再绑定银行卡": "请设置提现密码后再绑定银行卡",
    "修改登录密码": "修改登录密码",
    "保存": "保存",
    "旧密码": "旧密码",
    "请输入您的旧密码": "请输入您的旧密码",
    "新密码": "新密码",
    "请输入您的新密码": "请输入您的新密码",
    "请填写完整": "请填写完整",
    "两次密码输入不一致": "两次密码输入不一致",
    "修改真实姓名": "修改真实姓名",
    "姓名": "姓名",
    "请输入真实姓名": "请输入真实姓名",
    "为了您账户安全,真实姓名需要与绑定银行卡姓名一致": "为了您账户安全,真实姓名需要与绑定银行卡姓名一致",
    "请勿重复设置": "请勿重复设置",
    "请输入姓名": "请输入姓名",
    "设置资金密码": "设置资金密码",
    "请输入资金密码": "请输入资金密码",
    "请再次输入资金密码": "请再次输入资金密码",
    "两次密码不一致": "两次密码不一致",
    "性别修改": "性别修改",
    "设置": "设置",
    "基本信息设置": "基本信息设置",
    "登录密码": "登录密码",
    "资金密码": "资金密码",
    "退出登录": "退出登录",
    "提现密码已设置，需要修改请联系客服": "提现密码已设置，需要修改请联系客服",
    "已设置": "已设置",
    "提现中心": "提现中心",
    "提现记录": "提现记录",
    "提现金额 (元)": "提现金额 (元)",
    "到账时间：一般到账时间在5分钟左右，最快2分钟内到账": "到账时间：一般到账时间在5分钟左右，最快2分钟内到账",
    "说明": "说明",
    "余额": "余额",
    "马上提现": "马上提现",
    "请填写正确的金额": "请填写正确的金额",
    "金额": "金额",
    "提交时间": "提交时间",
    "审核时间": "审核时间",
    "没有更多了": "没有更多了",
    "播放": "播放",
    "次播放": "次播放",
    "热门推荐": "热门推荐",
    "账号下线": "账号下线",
    "请充值后观看视频": "请充值后观看视频",
    "直播间": "直播间",
    "官方认证女神": "官方认证女神",
    "已缴纳20000保证金": "已缴纳20000保证金",
    "地区": "地区",
    "签名": "签名",
    "获取联系方式": "获取联系方式",
    "温馨提示": "温馨提示",
    "联系数据老师激活权限": "联系数据老师激活权限",
    "取消": "取消",
    "标题": "标题",
    "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩": "服务项目：做爱3次 可无套内射 深喉口交 漫游 毒龙 按摩",
    "首页": "首页",
    "预约": "预约",
    "我的": "我的",

    "注": "注",
    "金额错误": "金额错误",
    "请选号": "请选号",
    "请填写金额": "请填写金额",
    "余额不足，请联系客服充值": "余额不足，请联系客服充值",
    "请联系管理员领取该任务": "请联系管理员领取该任务",
    "开奖成功，期号": "开奖成功，期号",
    "玫瑰": "玫瑰",
    "火箭": "火箭",
    "添加转数快":"添加转数快",
    "填写转数快":"填写转数快",
    "请输入您的转数快信息":"请输入您的转数快信息",
    "联系电话":"联系电话",
    "根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合":"根据银联风控要求同卡24小时内验证次数不能超过4次，感谢配合",
    "已存在提款方式，请勿重复绑定":"已存在提款方式，请勿重复绑定",




}