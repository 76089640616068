import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'


import 'video.js/dist/video-js.css'

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

Vue.prototype.$langArray = [{
    key: "中文繁体",
    value: 'zh-hk'
}, {
    key: "中文简体",
    value: "zh-cn"
}]
// }, {
//     key: "English",
//     value: "zh-en"
// }]

if (localStorage.getItem('lang') == '' || localStorage.getItem('lang') == null) {
    localStorage.setItem('lang', 'zh-hk');
    localStorage.setItem("language", "中文繁体")
}
const i18n = new VueI18n({
    locale: localStorage.getItem('language') || '中文繁体',
    // 使用localStorage缓存到本地，当下次使用时可默认当前使用语言
    messages: {
        '中文繁体': require('./utils/lang/zh-HK'),
        '中文简体': require('./utils/lang/zh-CN'),
        // 'English': require('./utils/lang/zh-EN'),
    },
})


Vue.prototype.$http = http
Vue.prototype.common = common
Vue.prototype.serviceUrl = window.location.protocol+"//api."+window.location.host.split(".").slice(-2).join('.')+"/"
// Vue.prototype.serviceUrl = window.location.protocol+"//api.localhost:8083/"
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
Vue.use(Vant);

var vues = new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
});
Vue.prototype.$bus = vues;
vues.$mount('#app');